import { ComponentActionsActionGroup } from '@/generated';
import { Button, Modal } from '@purposeinplay/core-v2';
import Link from 'next/link';
import React from 'react';
import AddToGroupBtn from '../AddToGroupBtn';
import { processMarkdown } from '@/utils/helpers';

type Props = {
  content: ComponentActionsActionGroup;
  isAuthenticated: boolean;
  setOpenModal: any;
  openModal: string | null;
};

const DynamicActionGroup: React.FC<Props> = ({
  content,
  isAuthenticated,
  setOpenModal,
  openModal,
}) => {
  return (
    <div className="flex flex-row items-center gap-2">
      {content?.Action?.map((action) => {
        const auth = action?.display_for?.auth_status;
        if (!isAuthenticated && auth === 'AUTHENTICATED') return;
        if (isAuthenticated && auth === 'UNAUTHENTICATED') return;
        return action?.href ? (
          <Link key={`action-${action?.id}`} href={action?.href} passHref>
            <Button
              color={action?.button_variant || 'primary'}
              ghost={action?.button_type === 'ghost'}
              stroke={action?.button_type === 'stroke'}
              className="max-w-max"
              linkHref={action?.href as string | undefined}
            >
              {action?.label}
            </Button>
          </Link>
        ) : action?.group_id ? (
          <AddToGroupBtn
            key={`action-${action?.id}`}
            color={action?.button_variant || 'primary'}
            ghost={action?.button_type === 'ghost'}
            stroke={action?.button_type === 'stroke'}
            groupId={action?.group_id}
            className="max-w-max"
          >
            {action?.label}
          </AddToGroupBtn>
        ) : (
          <Button
            key={`action-${action?.id}`}
            color={action?.button_variant || 'primary'}
            ghost={action?.button_type === 'ghost'}
            stroke={action?.button_type === 'stroke'}
            className="max-w-max"
          >
            {action?.label}
          </Button>
        );
      })}
      {content?.Modal?.map((modal) => {
        const auth = modal?.display_for?.auth_status;
        if (!isAuthenticated && auth === 'AUTHENTICATED') return;
        if (isAuthenticated && auth === 'UNAUTHENTICATED') return;
        return (
          <>
            <Button
              key={modal?.id}
              color={modal?.trigger?.button_variant || 'primary'}
              ghost={modal?.trigger?.button_type === 'ghost'}
              stroke={modal?.trigger?.button_type === 'stroke'}
              className="max-w-max"
              onClick={() => setOpenModal(modal?.id)}
            >
              {modal?.trigger?.label}
            </Button>
            <Modal
              title={modal?.title}
              isOpen={openModal === modal?.id}
              setIsOpen={() => setOpenModal(null)}
              size="3xl"
            >
              <div className="markdown-body">
                <div
                  dangerouslySetInnerHTML={{
                    __html: processMarkdown(modal?.content as string),
                  }}
                />
              </div>
            </Modal>
          </>
        );
      })}
    </div>
  );
};

export default DynamicActionGroup;
