import { Maybe } from '@/generated';
import { processMarkdown } from '@/utils/helpers';
import React from 'react';

type Props = {
  markdown: Maybe<string> | undefined;
};

const DynamicMarkdown: React.FC<Props> = ({ markdown }) => {
  if (!markdown || markdown?.length === 0) return null;
  return (
    <div className="[&>ol]:text-base [&>p]:text-base">
      <div className="markdown-body">
        <div
          dangerouslySetInnerHTML={{
            __html: processMarkdown(markdown),
          }}
        />
      </div>
    </div>
  );
};

export default DynamicMarkdown;
