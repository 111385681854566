'use client';
import {
  ComponentActionsAction,
  ComponentActionsActionGroup,
  ComponentActionsModalWindow,
  ComponentContentBanner,
  ComponentContentGamesCarousel,
  ComponentContentHelpersSpacing,
  ComponentContentImage,
  ComponentContentMarkdown,
  ComponentContentFaq,
} from '@/generated';
import { useMemo, useState } from 'react';
import { useAuth } from '@/hooks/auth';
import HeroSlider from '@/modules/home/components/hero-slider';
import DynamicGamesCarousel from '@/components/DynamicContent/dynamic-games-carousel';
import DynamicSpacing from '@/components/DynamicContent/dynamic-spacing';
import DynamicMarkdown from '@/components/DynamicContent/dynamic-markdown';
import DynamicImage from '@/components/DynamicContent/dynamic-image';
import DynamicModal from '@/components/DynamicContent/dynamic-modal';
import DynamicAction from '@/components/DynamicContent/dynamic-action';
import DynamicActionGroup from '@/components/DynamicContent/dynamic-action-group';
import { ComponentDisclosureType } from '@/types/others';
import Disclosure from '@/components/Disclosure';
import DynamicFAQ from '@/components/DynamicContent/dynamic-faq';

export const processDynamicContent = (
  content: any,
  isAuthenticated: boolean,
  openModal: any,
  setOpenModal: any,
  customRender?: any,
  customProps?: any,
) => {
  switch (content?.__typename) {
    case 'ComponentContentDisclosure': {
      const mkContent = content as ComponentDisclosureType;
      return <Disclosure content={mkContent?.disclosureContent} />;
    }
    case 'ComponentContentFaq': {
      const faqContent = content as ComponentContentFaq;
      return <DynamicFAQ content={faqContent} />;
    }
    case 'ComponentContentMarkdown': {
      const mkContent = content as ComponentContentMarkdown;
      const authStatus = mkContent?.display_for?.auth_status;
      if (!isAuthenticated && authStatus === 'AUTHENTICATED') return;
      if (isAuthenticated && authStatus === 'UNAUTHENTICATED') return;
      if (customRender) return customRender;
      return <DynamicMarkdown markdown={mkContent?.Markdown} />;
    }
    case 'ComponentContentBanner': {
      const bannerContent = content as ComponentContentBanner;
      const authStatus = bannerContent?.display_for?.auth_status;
      if (!isAuthenticated && authStatus === 'AUTHENTICATED') return;
      if (isAuthenticated && authStatus === 'UNAUTHENTICATED') return;
      return (
        <div className="relative">
          <HeroSlider slides={bannerContent?.banners?.data} />
        </div>
      );
    }
    case 'ComponentContentHelpersSpacing': {
      const spacingContent = content as ComponentContentHelpersSpacing;
      return <DynamicSpacing spacing={spacingContent?.spacing} />;
    }
    case 'ComponentContentGamesCarousel': {
      const carouselContent = content as ComponentContentGamesCarousel;
      return <DynamicGamesCarousel content={carouselContent} />;
    }
    case 'ComponentContentImage': {
      const imageContent = content as ComponentContentImage;
      const authStatus = imageContent?.display_for?.auth_status;
      if (!isAuthenticated && authStatus === 'AUTHENTICATED') return;
      if (isAuthenticated && authStatus === 'UNAUTHENTICATED') return;

      return <DynamicImage content={imageContent} />;
    }

    case 'ComponentActionsModalWindow':
      const modalContent = content as ComponentActionsModalWindow;
      const authStatus = modalContent?.display_for?.auth_status;
      if (!isAuthenticated && authStatus === 'AUTHENTICATED') return;
      if (isAuthenticated && authStatus === 'UNAUTHENTICATED') return;
      return (
        <DynamicModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          content={modalContent}
          {...customProps}
        />
      );
    case 'ComponentActionsAction': {
      const actionContent = content as ComponentActionsAction;
      const authStatus = actionContent?.display_for?.auth_status;
      if (!isAuthenticated && authStatus === 'AUTHENTICATED') return;
      if (isAuthenticated && authStatus === 'UNAUTHENTICATED') return;
      return <DynamicAction content={actionContent} />;
    }
    case 'ComponentActionsActionGroup': {
      const actionContent = content as ComponentActionsActionGroup;

      return (
        <DynamicActionGroup
          content={actionContent}
          openModal={openModal}
          setOpenModal={setOpenModal}
          isAuthenticated={isAuthenticated}
        />
      );
    }
  }
};

export const useDynamicContent = (
  content: any,
  field = 'content',
  customRender?: any,
  filterOut?: string[],
  customProps?: any,
) => {
  const [openModal, setOpenModal] = useState<string | null>(null);
  const { user } = useAuth();
  const dynamicContent = useMemo(() => {
    if (!content?.attributes) return null;
    // @ts-ignore
    return content?.attributes[field]
      ?.map((content: any) => {
        if (filterOut && filterOut?.includes(content?.__typename)) {
          return null;
        }
        return processDynamicContent(
          content,
          user?.isAuthenticated,
          openModal,
          setOpenModal,
          customRender,
          customProps,
        );
      })
      ?.filter(Boolean);
  }, [
    content?.attributes,
    field,
    filterOut,
    user?.isAuthenticated,
    openModal,
    customRender,
    customProps,
  ]);

  return dynamicContent;
};
