import React, { useMemo } from 'react';
import Section from '../HomeComponents/components/section';
import { useTranslation } from '@/app/i18n/client';
import { HomepageContentType } from '@/types/json-content/homepage';
import { ComponentContentGamesCarousel } from '@/generated';
import BadgeLink from '../BadgeLink';
import { useAllWildCategories } from '@/hooks/collections';
import Image from 'next/image';
import GamesCarousel from '../Carousels/games-carousel';

type Props = {
  content: ComponentContentGamesCarousel;
};

const DynamicGamesCarousel: React.FC<Props> = ({ content }) => {
  const { t } = useTranslation();
  const jsonContent = t('homepage') as HomepageContentType;

  const { data: collections, isLoading } = useAllWildCategories();

  const gamesCount = useMemo(() => {
    if (!collections) return;
    return collections?.reduce(function (result: any, item: any) {
      result[item?.id] = item?.games_count;
      return result;
    }, {} as any);
  }, [collections]);
  if (!content?.game_category_identity) return null;

  return (
    <Section
      titleStyles="mb-0"
      title={content?.section_title || 'Games'}
      href={
        content?.game_category_identity === '' ||
        content?.game_category_identity === 'all'
          ? '/games'
          : `/categories/${content?.game_category_identity}`
      }
      trailingElement={
        <BadgeLink
          isLoading={isLoading}
          text={jsonContent?.casino?.action?.btn_label}
          gamesCount={
            gamesCount && gamesCount[content?.game_category_identity as string]
          }
          href={
            !content?.game_category_identity ||
            content?.game_category_identity === '' ||
            content?.game_category_identity === 'all'
              ? '/games'
              : `/categories/${content?.game_category_identity}`
          }
        />
      }
      icon={
        <Image
          src="/assets/category-icons/controller.svg"
          fill
          alt={`Categories ${content?.game_category_identity} icon`}
        />
      }
    >
      <GamesCarousel
        tagType="primary"
        tagText="new"
        identifier={content?.game_category_identity}
      />
    </Section>
  );
};

export default DynamicGamesCarousel;
