'use client';

import React, { memo } from 'react';
import FAQItem from './faq-item';
import { cn } from '@/utils/style';
import { ComponentContentFaq } from '@/generated';
import { Heading, Text } from '@purposeinplay/core-v2';
import { processMarkdown } from '@/utils/helpers';

interface Props {
  content: ComponentContentFaq;
  className?: string;
  stylingOptions?: {
    title?: string;
    subtitle?: string;
    header?: string;
    container?: string;
  };
}

const DynamicFAQ = memo(({ content, stylingOptions }: Props) => {
  // @ts-ignore
  const { faqTitle, description, Item: questions } = content;

  if (!questions?.length) {
    return null;
  }

  return (
    <div className={cn(['w-full', stylingOptions?.container])}>
      {faqTitle && (
        <header
          className={cn([
            'mx-auto mb-spacing-sm flex flex-col items-center text-center md:max-w-2xl md:text-left',
            stylingOptions?.header,
          ])}
        >
          <Heading
            as="h2"
            className={cn([
              'text-xl font-bold leading-7 text-text-default md:text-2xl md:leading-8',
              description && 'mb-spacing-2xs',
              stylingOptions?.title,
            ])}
          >
            {faqTitle}
          </Heading>
          {description && (
            <div className="markdown-body">
              <div
                dangerouslySetInnerHTML={{
                  __html: processMarkdown(description as string),
                }}
              />
            </div>
          )}
        </header>
      )}
      <div className="grid-cols-1 gap-x-spacing-sm md:mx-auto">
        {questions.map((question) => (
          <FAQItem
            key={question?.id}
            title={question?.title}
            description={question?.description}
          />
        ))}
      </div>
    </div>
  );
});

DynamicFAQ.displayName = 'DynamicFAQ';

export default DynamicFAQ;
