import React, { useMemo } from 'react';
import HeroSlide from './hero-slide';
import {
  HeroCarousel,
  HeroCarouselEntity,
  useHeroCarouselsQuery,
} from '@/generated';
import Link from 'next/link';
import { cn } from '@/utils/style';
import { DEFAULT_CONTENT_STALE_TIME } from '@/utils/constants';
import { usePlayerStats } from '@/hooks/others';
import { useAuth } from '@/hooks/auth';
import { filterSlide } from '@/modules/home/utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { HeroSlideLoader } from '@/components/Loaders';
import CarouselWithGradient from '@/components/Carousels/components/carousel-with-gradient';
import { useAnalytics } from '@/hooks/use-analytics';
import { usePathname } from 'next/navigation';

dayjs.extend(utc);

const HeroSlider: React.FC<{
  slides?: HeroCarouselEntity[];
  isLoading?: boolean;
}> = React.memo(({ slides, isLoading }) => {
  const pathname = usePathname();
  const { user } = useAuth();
  const { data, isLoading: slidesLoading } = useHeroCarouselsQuery(undefined, {
    queryKey: ['heroCarousels'],
    staleTime: DEFAULT_CONTENT_STALE_TIME,
    enabled: !slides ? true : false,
  });

  const { track } = useAnalytics();

  const { data: playerStats, isLoading: playerStatsLoading } = usePlayerStats();

  const heroSlides = useMemo(
    () => (slides ? slides : (data && data?.heroCarousels?.data) || []),
    [data, slides],
  );

  const parsedSlides = useMemo((): HeroCarousel[] => {
    if (slidesLoading || user?.isLoading || playerStatsLoading) return [];
    if (
      !user?.isAuthenticated &&
      !slides &&
      pathname !== '/casino' &&
      !pathname?.includes('themes')
    )
      return [];

    const userDepositsCount = playerStats?.deposits_count;
    const sortedSlides =
      heroSlides?.sort(
        (a: any, b: any) => a?.attributes?.order - b?.attributes?.order,
      ) || [];

    const availableSlides = sortedSlides.filter((slide: any) => {
      return filterSlide(
        slide.attributes,
        userDepositsCount,
        user?.isAuthenticated,
      );
    });

    const carouselItems = availableSlides.map(
      (slide) => slide.attributes,
    ) as HeroCarousel[];

    return carouselItems?.filter((carousel) => {
      if (!carousel?.valid_until) {
        return carousel;
      } else {
        const shouldShow = dayjs(carousel?.valid_until)
          .utc()
          .isAfter(dayjs().utc());

        return shouldShow;
      }
    });
  }, [
    user,
    heroSlides,
    playerStats,
    playerStatsLoading,
    slidesLoading,
    slides,
    pathname,
  ]);

  const carouselSlides = useMemo(() => {
    return parsedSlides?.map((item: HeroCarousel) => {
      return (
        <div key={`slide-${item?.path}`}>
          <Link
            prefetch={false}
            href={item?.path as string}
            onClick={() => {
              track('slide_viewed', {
                category: 'carousel',
                label: item?.path,
              });
            }}
          >
            <HeroSlide color={item.color} item={item} />
          </Link>
        </div>
      );
    });
  }, [parsedSlides, track]);

  if (slidesLoading || isLoading) {
    return (
      <div className="mb-spacing-lg md:mb-spacing-xl">
        <div className="flex flex-row space-x-2 overflow-hidden">
          <HeroSlideLoader className="mb-0 md:mb-0" />
          <HeroSlideLoader className="mb-0 md:mb-0" />
          <HeroSlideLoader className="mb-0 md:mb-0" />
          <HeroSlideLoader className="mb-0 md:mb-0" />
        </div>
      </div>
    );
  }

  return (
    <CarouselWithGradient
      slideWidth={400}
      items={carouselSlides}
      space="12"
      withPagination
      showArrows
      withGradient={false}
    />
  );
});

HeroSlider.displayName = 'HeroSlider';

export default HeroSlider;
