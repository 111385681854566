import React from 'react';
import { processMarkdown } from '@/utils/helpers';
import DisclosureAccordion from '../DisclosureAccordion';
import { cn } from '@/utils/style';

const Disclosure = React.memo(
  ({
    buttonTextOpen,
    buttonTextClosed,
    footerStyles,
    accordionStyles,
    content = '',
    className,
    forGames,
    ...props
  }: {
    buttonTextOpen?: string;
    buttonTextClosed?: string;
    content?: string;
    footerStyles?: string;
    accordionStyles?: string;
    className?: string;
    forGames?: boolean;
  }) => {
    return content?.length > 0 ? (
      <div
        className={cn(['my-4 flex w-full justify-center lg:my-7', className])}
        {...props}
      >
        {content.length > 500 ? (
          <DisclosureAccordion
            backgroundGradient={false}
            accordionStyles={cn([
              'bg-transparent lg:max-w-132 mx-auto',
              accordionStyles,
            ])}
          >
            <div className="[&>h2]:text-center">
              <div className="markdown-body">
                <div
                  dangerouslySetInnerHTML={{
                    __html: processMarkdown(content),
                  }}
                />
              </div>
            </div>
          </DisclosureAccordion>
        ) : (
          <div className={cn(['mx-auto lg:w-3/4', forGames && 'ml-0 w-full'])}>
            <div className="[&>h2]:text-center">
              <div className="markdown-body">
                <div
                  dangerouslySetInnerHTML={{
                    __html: processMarkdown(content),
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    ) : null;
  },
);

Disclosure.displayName = 'PageDisclosure';

export default Disclosure;
