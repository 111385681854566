import { ComponentActionsModalWindow } from '@/generated';
import { processMarkdown } from '@/utils/helpers';
import { Button, Modal } from '@purposeinplay/core-v2';
import React from 'react';

type Props = {
  content: ComponentActionsModalWindow;
  setOpenModal: any;
  openModal: string | null;
};
const DynamicModal: React.FC<Props> = ({
  content,
  setOpenModal,
  openModal,
  ...rest
}) => {
  return (
    <div>
      <Button
        color={content?.trigger?.button_variant || 'primary'}
        ghost={content?.trigger?.button_type === 'ghost'}
        stroke={content?.trigger?.button_type === 'stroke'}
        className="max-w-max"
        onClick={() => setOpenModal(content?.id)}
      >
        {content?.trigger?.label}
      </Button>
      <Modal
        title={content?.title}
        isOpen={openModal === content?.id}
        setIsOpen={() => setOpenModal(null)}
        size="3xl"
        {...rest}
      >
        <div className="markdown-body">
          <div
            dangerouslySetInnerHTML={{
              __html: processMarkdown(content?.content as string),
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default DynamicModal;
