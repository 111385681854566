import { HeroCarousel } from '@/generated';
import { getDayOfTheWeek } from '@/utils/helpers';
import dayjs from 'dayjs';

export const filterSlide = (
  slide: HeroCarousel,
  userDepositsCount?: number,
  isAuthenticated?: boolean,
) => {
  if (
    slide.display_for?.auth_status === 'UNAUTHENTICATED' &&
    !isAuthenticated
  ) {
    return slide;
  }

  const currentWeekDay = dayjs().weekday();
  const availabilities = slide?.availabilities?.data.map((availability) =>
    availability.attributes?.day?.toLowerCase(),
  );
  const slideDepositCounts = slide?.deposit_counts?.data.map(
    (count) => count?.attributes?.number,
  );

  if (
    availabilities &&
    availabilities.length > 0 &&
    slideDepositCounts &&
    slideDepositCounts.length > 0
  ) {
    return (
      availabilities.includes(getDayOfTheWeek(currentWeekDay)) &&
      slideDepositCounts.includes(userDepositsCount)
    );
  }
  if (
    availabilities &&
    availabilities.length > 0 &&
    slideDepositCounts &&
    slideDepositCounts.length === 0
  ) {
    return availabilities.includes(getDayOfTheWeek(currentWeekDay));
  }
  if (
    availabilities &&
    availabilities.length === 0 &&
    slideDepositCounts &&
    slideDepositCounts.length > 0
  ) {
    return slideDepositCounts.includes(userDepositsCount);
  }
  return slide;
};
