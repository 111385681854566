import { ComponentContentImage } from '@/generated';
import { CONTENT_API_URL } from '@/utils/constants';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

type Props = {
  content: ComponentContentImage;
};

const DynamicImage: React.FC<Props> = ({ content }) => {
  return content?.href ? (
    <div className="relative h-96">
      <Link
        href={content?.href}
        className="relative inline-block h-full w-full"
      >
        <Image
          src={`${CONTENT_API_URL}${content?.image?.data?.attributes?.url}`}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
          alt={`Content Image ${content?.id}`}
          className="h-full !max-h-100 w-full rounded-xl object-top"
          fill
        />
      </Link>
    </div>
  ) : (
    <Image
      src={`${CONTENT_API_URL}${content?.image?.data?.attributes?.url}`}
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
      alt={`Content Image ${content?.id}`}
      fill
    />
  );
};

export default DynamicImage;
