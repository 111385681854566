import { Maybe } from '@/generated';
import React from 'react';

type Props = {
  spacing?: Maybe<number> | undefined;
};

const DynamicSpacing: React.FC<Props> = ({ spacing }) => {
  return (
    <div
      className="relative"
      style={{
        height: `${spacing}px` || 0,
      }}
    />
  );
};

export default DynamicSpacing;
