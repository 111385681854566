import { useTranslation } from '@/app/i18n/client';
import { useAddPlayerToGroups } from '@/hooks/groups';
import { useToast } from '@purposeinplay/utils';
import React, { useCallback, useMemo } from 'react';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';
import { Button } from '@purposeinplay/core-v2';
import { cn } from '@/utils/style';
import { useAuth } from '@/hooks/auth';

type ButtonColorsProps = 'primary' | 'secondary' | 'tertiary' | 'critical';

type ButtonSizesProps = 'xs' | 'sm' | 'md' | 'lg';
type Props = {
  groupId: string;
  /** The size of the button */
  size?: ButtonSizesProps;
  /** The color of the button */
  color?: ButtonColorsProps;
  /** If button is disabled or not */
  disabled?: boolean;
  /** If true the button will display a spinner */
  isLoading?: boolean;
  /** If true the button will have ghost styles */
  ghost?: boolean;
  /** If true the button will have stroke styles */
  stroke?: boolean;
  /** Custom styles applied through className */
  className?: string;
  children?: React.ReactNode;
};

const AddToGroupBtn: React.FC<Props> = ({
  groupId,
  color = 'primary',
  stroke,
  size = 'md',
  ghost,
  className,
  children,
}) => {
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const { user } = useAuth();
  const { mutateAsync: addPlayerToGroups, isPending: atgLoading } =
    useAddPlayerToGroups();
  const alreadyOptIn = useMemo(() => {
    const found = user?.currentUser?.statuses?.find(
      (elem: any) => elem.id === groupId,
    );
    return found ? true : false;
  }, [user?.currentUser?.statuses, groupId]);
  const handleClick = useCallback(() => {
    if (alreadyOptIn) return;
    addPlayerToGroups([groupId]).then(() =>
      toast({
        state: 'success',
        title: toastMessages?.opted_in,
      }),
    );
  }, [
    addPlayerToGroups,
    alreadyOptIn,
    groupId,
    toast,
    toastMessages?.opted_in,
  ]);

  return (
    <Button
      disabled={atgLoading || alreadyOptIn}
      isLoading={atgLoading}
      onClick={handleClick}
      color={color}
      stroke={stroke}
      size={size}
      ghost={ghost}
      className={cn(['min-w-max', className])}
    >
      {alreadyOptIn ? toastMessages?.opted_in : children}
    </Button>
  );
};

export default AddToGroupBtn;
