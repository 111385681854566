import { ComponentActionsAction } from '@/generated';
import Link from 'next/link';
import React from 'react';
import AddToGroupBtn from '../AddToGroupBtn';
import { Button } from '@purposeinplay/core-v2';

type Props = {
  content: ComponentActionsAction;
};

const DynamicAction: React.FC<Props> = ({ content }) => {
  return content?.href ? (
    <Link href={content?.href} passHref>
      {content?.group_id ? (
        <AddToGroupBtn
          color={content?.button_variant || 'primary'}
          ghost={content?.button_type === 'ghost'}
          stroke={content?.button_type === 'stroke'}
          groupId={content?.group_id}
          className="max-w-max"
        >
          {content?.label}
        </AddToGroupBtn>
      ) : (
        <Button
          color={content?.button_variant || 'primary'}
          ghost={content?.button_type === 'ghost'}
          stroke={content?.button_type === 'stroke'}
          className="max-w-max"
          linkHref={content?.href as string | undefined}
        >
          {content?.label}
        </Button>
      )}
    </Link>
  ) : content?.group_id ? (
    <AddToGroupBtn
      color={content?.button_variant || 'primary'}
      ghost={content?.button_type === 'ghost'}
      stroke={content?.button_type === 'stroke'}
      groupId={content?.group_id}
      className="max-w-max"
    >
      {content?.label}
    </AddToGroupBtn>
  ) : (
    <Button
      color={content?.button_variant || 'primary'}
      ghost={content?.button_type === 'ghost'}
      stroke={content?.button_type === 'stroke'}
      className="max-w-max"
    >
      {content?.label}
    </Button>
  );
};

export default DynamicAction;
