import { HeroCarousel } from '@/generated';
import { CONTENT_API_URL } from '@/utils/constants';
import Image from 'next/image';
import React from 'react';

interface Props {
  item: HeroCarousel;
  color: string;
}

const HeroSlide = React.memo(({ item, color }: Props) => {
  const imageUrl = item?.background?.data?.attributes?.url;

  return (
    <div
      className="relative z-10 flex min-h-[12rem] w-[25rem] overflow-hidden rounded-3xl bg-bgr-default"
      style={{
        backgroundColor: color,
        WebkitMaskImage: '-webkit-radial-gradient(white, black)',
      }}
    >
      <div className="absolute inset-0 flex items-center">
        <Image
          className={`object-cover object-left xs:object-right`}
          fill
          src={`${CONTENT_API_URL}${imageUrl}`}
          alt={`Carousel slide for ${item?.path}`}
        />
      </div>
    </div>
  );
});

HeroSlide.displayName = 'HeroSlide';

export default HeroSlide;
