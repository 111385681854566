import React, { useState } from 'react';
import { cn } from '@/utils/style';
import { Accordion } from '@purposeinplay/core-v2';
import { processMarkdown } from '@/utils/helpers';
import { Maybe } from '@/generated';

interface Props {
  title?: Maybe<string> | undefined;
  description?: Maybe<string> | undefined;
  stylingOptions?: {
    container?: string;
    detailStyles?: string;
  };
}

const FAQItem = ({ title, description, stylingOptions }: Props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      detailStyles={cn([
        'border-b border-border-general-default last:border-b-transparent',
        stylingOptions?.detailStyles,
      ])}
      customStyles={cn(['border-none', stylingOptions?.container])}
      title={title || ''}
      type="underline"
      expanded={expanded}
      onClick={() => setExpanded(!expanded)}
    >
      <div className="markdown-body">
        <div
          dangerouslySetInnerHTML={{
            __html: processMarkdown(description as string),
          }}
        />
      </div>
    </Accordion>
  );
};

export default FAQItem;
