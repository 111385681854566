import React from 'react';
import { Button } from '@purposeinplay/core-v2';
import { useState } from 'react';
import { cn } from '@/utils/style';

interface AccordionProps {
  closedHeight?: string;
  openHeight?: string;
  accordionStyles?: string;
  footerStyles?: string;
  buttonTextClosed?: string;
  buttonTextOpened?: string;
  backgroundGradient?: boolean;
  disableClose?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  disabledButton?: boolean;
  disablePadding?: boolean;
  disabled?: boolean;
}

const DisclosureAccordion = React.memo(
  ({
    children,
    closedHeight,
    openHeight,
    onClick,
    buttonTextClosed = 'Read more',
    buttonTextOpened = 'Read less',
    accordionStyles,
    footerStyles,
    backgroundGradient = true,
    disableClose = false,
    disabledButton,
    disabled,
  }: AccordionProps) => {
    const [accordionOpen, setAccordionOpen] = useState(false);

    const handleOnClick = () => {
      if (disableClose) {
        setAccordionOpen(true);
        onClick && onClick();
      } else {
        setAccordionOpen((prev) => !prev);
        !accordionOpen && onClick && onClick();
      }
    };

    return (
      <div>
        <div
          className={cn([
            'relative max-h-[26.75rem] transform-gpu overflow-hidden rounded-b-3xl bg-bgr-default bg-gradient-to-t from-transparent to-[var(--bgr-default)] p-2 transition-all duration-200 ease-in-out',
            accordionOpen && `max-h-[900rem]`,
            closedHeight && closedHeight,
            accordionOpen && openHeight && openHeight,
            accordionStyles && accordionStyles,
          ])}
        >
          {children}
          {!disabledButton && <div className="py-6">&nbsp;</div>}
          <div>
            {!disabledButton && (
              <div
                className={cn([
                  'absolute bottom-0 left-0 z-20 flex h-40 w-full items-end justify-center bg-gradient-to-t from-[#ebeced] to-transparent pt-6 shadow-xl dark:from-[#0a1c2d]',
                  accordionOpen && 'bg-none',
                  !accordionOpen &&
                    backgroundGradient &&
                    "before: before:pointer-events-none before:absolute before:bottom-0 before:left-0 before:h-[20rem] before:w-full before:bg-gradient-to-t before:from-[#ebeced] before:to-transparent  before:content-[''] before:dark:from-[#0a1c2d]",
                  footerStyles && footerStyles,
                ])}
              >
                <Button
                  color="tertiary"
                  onClick={handleOnClick}
                  size="md"
                  disabled={disabled}
                  className={cn([
                    disabledButton && `opacity-25`,
                    `cursor-pointer`,
                  ])}
                >
                  {accordionOpen && !disableClose
                    ? buttonTextOpened
                    : buttonTextClosed}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);

DisclosureAccordion.displayName = 'DisclosureAccordion';

export default DisclosureAccordion;
